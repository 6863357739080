const LanguagesObj = {
    "langList": [
        {"iso_639_1":"cs","english_name":"Czech","name":"Český"},
        {"iso_639_1":"de","english_name":"German","name":"Deutsch"},
        {"iso_639_1":"vi","english_name":"Vietnamese","name":"Tiếng Việt"},
        {"iso_639_1":"it","english_name":"Italian","name":"Italiano"},
        {"iso_639_1":"pl","english_name":"Polish","name":"Polski"},
        {"iso_639_1":"da","english_name":"Danish","name":"Dansk"},
        {"iso_639_1":"sk","english_name":"Slovak","name":"Slovenčina"},
        {"iso_639_1":"en","english_name":"English","name":"English"},
        {"iso_639_1":"hu","english_name":"Hungarian","name":"Magyar"},
        {"iso_639_1":"ru","english_name":"Russian","name":"Pусский"},
        {"iso_639_1":"sr","english_name":"Serbian","name":"Srpski"},
        {"iso_639_1":"sv","english_name":"Swedish","name":"svenska"},
        {"iso_639_1":"ka","english_name":"Georgian","name":"ქართული"},
        {"iso_639_1":"be","english_name":"Belarusian","name":"беларуская мова"},
        {"iso_639_1":"fi","english_name":"Finnish","name":"suomi"},
        {"iso_639_1":"fr","english_name":"French","name":"Français"},
        {"iso_639_1":"id","english_name":"Indonesian","name":"Bahasa indonesia"},
        {"iso_639_1":"ja","english_name":"Japanese","name":"日本語"},
        {"iso_639_1":"ko","english_name":"Korean","name":"한국어/조선말"},
        {"iso_639_1":"nl","english_name":"Dutch","name":"Nederlands"},
        {"iso_639_1":"el","english_name":"Greek","name":"ελληνικά"},
        {"iso_639_1":"ar","english_name":"Arabic","name":"العربية"},
        {"iso_639_1":"tr","english_name":"Turkish","name":"Türkçe"},
        {"iso_639_1":"pt","english_name":"Portuguese","name":"Português"},
        {"iso_639_1":"th","english_name":"Thai","name":"ภาษาไทย"},
        {"iso_639_1":"lt","english_name":"Lithuanian","name":"Lietuvių"},
        {"iso_639_1":"sl","english_name":"Slovenian","name":"Slovenščina"},
        {"iso_639_1":"es","english_name":"Spanish","name":"Español"},
        {"iso_639_1":"zh","english_name":"Mandarin","name":"普通话"},
        {"iso_639_1":"he","english_name":"Hebrew","name":"עִבְרִית"},
        {"iso_639_1":"ro","english_name":"Romanian","name":"Română"},
        {"iso_639_1":"bg","english_name":"Bulgarian","name":"български език"}
    ]
};

const TransObj = {   
    "ar": {
        "english_name":"Arabic",     
        "section_labels": {"languages":"اللغات","regions":"المناطق","sort_options":"خيارات الفرز",
            "genre":"النوع","decade":"يعقد","provider":"مزود"},
        "trending":"رائج",
        "find":"البحث عن",
        "search":"البحث عن",
        "movie": "فيلم",
        "movies": "أفلام",
        "tv":"تلفزيون",
        "tv_series":"عرض تلفزيوني",
        "movie_title":"عنوان الفيلم",
        "all":"كل شئ",
        "sort_by": {
            "rating_desc":"التقييم: تنازلي",
            "rating_asc":"التقييم: تصاعدي",
            "date_desc":"تاريخ الإصدار: تنازلي",
            "date_asc":"تاريخ الإصدار: تصاعدي"
        }, 
        "sr-only": {
            "back_arrow":"زر السهم للخلف: الصفحة السابقة",
            "forward_arrow":"زر السهم للأمام: الصفحة التالية",
            "up_arrow":"زر السهم لأعلى: إغلاق القائمة",
            "down_arrow":"زر السهم لأسفل: فتح القائمة",
            "close_menu":"زر لإغلاق القائمة",
            "deactivated":"معطل",
            "eye_icon":"رمز العين",
            "viewing_options":"خيارات مشاهدة",
            "more_options":"المزيد من الخيارات",
            "info_icon": "رمز المعلومات",
            "more_info":"معلومات اكثر",
            "film_icon":"أيقونة الفيلم",
            "tv_icon":"التلفزيون",
            "search_bar":"(ابحث)أيقونة العدسة المكبرة"
        },
        "movie_info":{"cast":"ممثلين", "directing":"إخراج", "screenplay":"كتابة السيناريو", "release_date":" تاريخ الإصدار"},
        "status_messages":{"loading":"يتم التحميل","failed_to_load":"فشل في التحميل","form_modal":"إختر خيار","no_results":"لا نتائج"},
        "provider_options": {"buy":"اشتريها","rent":"إيجار","stream":"يعرض الان","with_ads":"مع الإعلانات"}
    },
    "be": {
        "english_name":"Belarusian",
        "section_labels": {"languages":"мовы","regions":"рэгіёнах","sort_options":"параметры сартавання",
            "genre":"Жанр","decade":"Дзесяцігоддзе","provider":"Правайдэр"},
        "trending":"у трэндзе",
        "find":"Знайсці",
        "search":"шукаць",
        "movie":"фільм",
        "movies":"Фільмы",
        "tv":"TV",
        "tv_series":"тэлеперадачы",
        "movie_title":"Назва фільма",
        "all":"усё",
        "sort_by": {
            "rating_desc":"Ацэнка: па змяншэнні",
            "rating_asc":"Рэйтынг: па ўзрастанні",
            "date_desc":"Дата выпуску: па змяншэнні",
            "date_asc":"Дата выпуску: па ўзрастанні"
        }, 
        "sr-only": {
            "back_arrow":"Кнопка са стрэлкай назад: папярэдняя старонка",
            "forward_arrow":"Кнопка са стрэлкай наперад: наступная старонка",
            "up_arrow":"Кнопка са стрэлкай уверх: закрыць меню",
            "down_arrow":"Кнопка са стрэлкай уніз: адкрыць меню",
            "close_menu":"закрыць меню",
            "deactivated":"дэактываваны",
            "eye_icon":"сімвал вачэй",
            "viewing_options":"варыянты прагляду",
            "more_options":"Больш варыянтаў",
            "info_icon": "інфармацыйны сімвал",
            "more_info":"Дадатковая інфармацыя",
            "film_icon":"сімвал фільма",
            "tv_icon":"Тэлевізійны сімвал",
            "search_bar":"сімвал павелічальнага шкла (шукаць)"
        },
        "movie_info":{"cast":"Акцёры", "directing":"рэжысёр", "screenplay":"Сцэнарная праца", "release_date":"Дата выпуску"},
        "status_messages":{"loading":"атрыманне вынікаў","failed_to_load":"Не ўдалося загрузіць","form_modal":"Выберыце варыянт","no_results":"Няма вынікаў"},
        "provider_options": {"buy":"Купіць","rent":"Арэнда","stream":"Stream","with_ads":"З рэкламай"}
    },
    "bg": {
        "english_name":"Bulgarian",
        "section_labels": {"languages":"езици","regions":"региони","sort_options":"опции за сортиране",
            "genre":"Жанр","decade":"Десетилетие","provider":"Доставчик"},
        "trending":"Набиращи популярност",
        "find":"Намерете",
        "search":"търсене на",
        "movie":"филм",
        "movies":"Филми",
        "tv":"TB",
        "tv_series":"сериал",
        "movie_title":"Заглавие на филм",
        "all":"Всичко",
        "sort_by": {
            "rating_desc":"Рейтинг Низходящ",
            "rating_asc":"Рейтинг: Възходящ",
            "date_desc":"Дата на издаване в низходящ ред",
            "date_asc":"Дата на издаване във възходящ ред"
        }, 
        "sr-only": {
            "back_arrow":"Бутон със стрелка назад: предишна страница",
            "forward_arrow":"Бутон със стрелка напред: следваща страница",
            "up_arrow":"Бутон със стрелка нагоре: затваряне на менюто",
            "down_arrow":"Бутон със стрелка надолу: отваряне на менюто",
            "close_menu":"затваряне на менюто",
            "deactivated":"деактивиран",
            "eye_icon":"символ на очите",
            "viewing_options":"опции за гледане",
            "more_options":"повече опций",
            "info_icon": "Информационен символ",
            "more_info":"повече информация",
            "film_icon":"Символ на филма",
            "tv_icon":"ТВ символ",
            "search_bar":"символ на лупа(търсене)"
        },
        "movie_info":{"cast":"Актьори", "directing":"Режисиране", "screenplay":"Сценарист", "release_date":"Дата на излизане"},
        "status_messages":{"loading":"получаване на резултати","failed_to_load":"изтеглянето се провали","form_modal":"Направете избор","no_results":"Няма резултати"},
        "provider_options": {"buy":"Купува","rent":"под наем","stream":"Стриймове","with_ads":"С реклами"}
    },
    "cs":{
        "english_name":"Czech",
        "section_labels": {"languages":"jazyky","regions":"regionech","sort_options":"možnosti řazení",
            "genre":"Žánr","decade":"Desetiletí","provider":"Poskytovatel"},
        "trending":"Nejnavštěvovanější",
        "find":"Najděte",
        "search":"Vyhledávejte",
        "movie":"filmů",
        "movies":"filmy",
        "tv":"televize",
        "tv_series":"televizní seriál",
        "movie_title":"Název filmu",
        "all":"Všechno",
        "sort_by": {
            "rating_desc":"Hodnocení: Sestupné",
            "rating_asc":"Hodnocení: Vzestupně",
            "A_Z":"Název: A-Z",
            "Z_A":"Název: Z-A",
            "date_desc":"Datum vydání: Sestupně",
            "date_asc":"Datum vydání: Vzestupně"
        }, 
        "sr-only": {
            "back_arrow":"Tlačítko se šipkou zpět: předchozí stránka",
            "forward_arrow":"Tlačítko se šipkou vpřed: další stránka",
            "up_arrow":"Tlačítko šipka nahoru: zavřít nabídku vyhledávání",
            "down_arrow":"Tlačítko šipka dolů: otevřete nabídku vyhledávání",
            "close_menu":"zavřít nabídku vyhledávání",
            "deactivated":"deaktivován",
            "eye_icon":"symbol oka",
            "viewing_options":"možnosti poskytovatele",
            "more_options":"více možností",
            "info_icon": "Informační symbol",
            "more_info":"více informací",
            "film_icon":"symbol filmu",
            "tv_icon":"symbol TV",
            "search_bar":"symbol lupy (hledat)"
        },
        "movie_info":{"cast":"Herci", "directing":"režie", "screenplay":"Scénáristika", "release_date":"Datum vydání"},
        "status_messages":{"loading":"získávání výsledků","failed_to_load":"se nepodařilo stáhnout","form_modal":"Vyberte možnost","no_results":"Žádné výsledky"},
        "provider_options": {"buy":"Koupit","rent":"Pronajmout si","stream":"Streamované","with_ads":"S reklamami"}
    },
    "da": {
        "english_name":"Danish",
        "section_labels": {"languages":"Sprog","regions":"regioner","sort_options":"sorteringsmuligheder",
            "genre":"Genre","decade":"Årti","provider":"Udbyder"},
        "trending":"Trending",
        "find":"Find",
        "search":"Søg efter",
        "movie":"film",
        "movies":"film",
        "tv":"TV",
        "tv_series":"TV-serie",
        "movie_title":"Název filmu",
        "all":"Alt",
        "sort_by": {
            "rating_desc":"Bedømmelse: Faldende",
            "rating_asc":"Bedømmelse: Stigende",
            "A_Z":"Titel: A-Z",
            "Z_A":"Titel: Z-A",
            "date_desc":"Udgivelsesdato: Faldende",
            "date_asc":"Udgivelsesdato: Stigende"
        }, 
        "sr-only": {
            "back_arrow":"Pil tilbage: næste side",
            "forward_arrow":"Pil frem: forrige side",
            "up_arrow":"Pil op: luk menuen",
            "down_arrow":"Pil ned: Åbn menu",
            "close_menu":"luk menuen",
            "deactivated":"deaktiveret",
            "eye_icon":"øje symbol",
            "viewing_options":"muligheder for filmvisning",
            "more_options":"flere muligheder",
            "info_icon": "informationssymbol",
            "more_info":"mere information",
            "film_icon":"film symbol",
            "tv_icon":"TV symbol",
            "search_bar":"forstørrelsesglas symbol"
        },
        "movie_info":{"cast":"skuespiller", "directing":"instruere", "screenplay":"manuskriptskrivning", "release_date":"Udgivelsesdato"},
        "status_messages":{"loading":"indlæsning af oplysninger","failed_to_load":"kunne ikke downloades","form_modal":"Vælg en mulighed","no_results":"Ingen resultater"},
        "provider_options": {"buy":"Købe","rent":"Leje","stream":"Stream","with_ads":"Med annoncer"}
    },
    "de": {
        "english_name":"German",
        "section_labels": {"languages":"Sprachen","regions":"Regionen","sort_options":"Sortiermöglichkeiten",
            "genre":"Genre","decade":"Jahrzehnt","provider":"Anbieter"},
        "trending":"Trends",
        "find":"Finden Sie",
        "search":"Suche nach",
        "movie":"Film",
        "movies":"Filme",
        "tv":"Fernsehserien",
        "tv_series":"serie",
        "movie_title":"Filmtitel",
        "all":"Alles",
        "sort_by": {
            "rating_desc":"Bewertung: Absteigend",
            "rating_asc":"Bewertung: Aufsteigend",
            "A_Z":"Titel: A-Z",
            "Z_A":"Titel: Z-A",
            "date_desc":"Erscheinungsdatum: Absteigend",
            "date_asc":"Erscheinungsdatum: Aufsteigend"
        }, 
        "sr-only": {
            "back_arrow":"Zurück-Pfeiltaste: nächste Seite",
            "forward_arrow":"Vorwärtspfeiltaste: nächste Seite",
            "up_arrow":"Pfeiltaste nach oben: Menü schließen",
            "down_arrow":"Pfeiltaste nach unten: Menü öffnen",
            "close_menu":"Menü schließen",
            "deactivated":"deaktiviert",
            "eye_icon":"Augensymbol",
            "viewing_options":"Optionen zum Ansehen",
            "more_options":"Mehr Optionen",
            "info_icon": "informationssymbol",
            "more_info":"Mehr Informationen",
            "film_icon":"Filmsymbol",
            "tv_icon":"TV-Symbol",
            "search_bar":"Lupensymbol glass icon (suchen)"
        },
        "movie_info":{"cast":"schauspieler", "directing":"Regisseur", "screenplay":"Drehbuchschreiben", "release_date":"Erscheinungsdatum"},
        "status_messages":{"loading":"Daten werden geladen","failed_to_load":"Laden fehlgeschlagen","form_modal":"Wähle eine Option","no_results":"keine Ergebnisse"},
        "provider_options": {"buy":"Kaufen","rent":"Mieten","stream":"Stream","with_ads":"Mit Werbung"}
    },
    "el": {
        "english_name":"Greek",
        "section_labels": {"languages":"Γλώσσες","regions":"περιφέρειες","sort_options":"επιλογές ταξινόμησης",
            "genre":"Είδος","decade":"Δεκαετία","provider":"Προμηθευτής"},
        "trending":"Τάσεις",
        "find":"Βρείτε",
        "search":"αναζήτηση για",
        "movie":"ταινία",
        "movies":"Ταινίες",
        "tv":"Τηλεόραση",
        "tv_series":"τηλεοπτικές εκπομπές",
        "movie_title":"Τίτλος ταινίας",
        "all":"Τα παντα",
        "sort_by": {
            "rating_desc":"Η βαθμολογία φθίνουσα",
            "rating_asc":"βαθμολογία αύξουσα",
            "date_desc":"φθίνουσα ημερομηνία κυκλοφορίας",
            "date_asc":"ημερομηνία κυκλοφορίας αύξουσα"
        }, 
        "sr-only": {
            "back_arrow":"κουμπί βέλους προς τα πίσω",
            "forward_arrow":"κουμπί βέλους προς τα εμπρός",
            "up_arrow":"Κουμπί επάνω βέλους: κλείστε το μενού",
            "down_arrow":"Κουμπί κάτω βέλους: ανοίξτε το μενού",
            "close_menu":"κλείστε το μενού",
            "deactivated":"απενεργοποιημένο",
            "eye_icon":"σύμβολο ματιών",
            "viewing_options":"Επιλογές προβολής ταινίας",
            "more_options":"περισσότερες επιλογές",
            "info_icon": "σύμβολο πληροφοριών",
            "more_info":"περισσότερες πληροφορίες",
            "film_icon":"σύμβολο ταινίας",
            "tv_icon":"σύμβολο της τηλεόρασης",
            "search_bar":"σύμβολο μεγεθυντικού φακού (ζητώ)"
        },
        "movie_info":{"cast":"ηθοποιοί", "directing":"σκηνοθέτης", "screenplay":"Σεναριογράφος", "release_date":"ημερομηνία κυκλοφορίας"},
        "status_messages":{"loading":"λήψη αποτελεσμάτων","failed_to_load":"απέτυχε η λήψη","form_modal":"Διάλεξε μια επιλογή","no_results":"Δεν υπάρχουν αποτελέσματα που να ταιριάζουν"},
        "provider_options": {"buy":"Αγορά","rent":"Ενοίκιο","stream":"Σε ροή","with_ads":"Με διαφήμιση"}
    },
    "en": {
        "english_name":"English",
        "section_labels": {"languages":"languages","regions":"regions","sort_options":"sort options",
            "genre":"Genre","decade":"Decade","provider":"Provider"},
        "trending":"Trending",
        "find":"Find",
        "search":"Search",
        "person":"person's name",
        "movie":"movie",
        "movies":"movies",
        "tv":"TV",
        "tv_series":"TV Series",
        "movie_title":"Movie title",
        "all":"All",
        "sort_by": {
            "rating_desc":"Rating: high to low",
            "rating_asc":"Rating: low to high",
            "A_Z":"Titles: A-Z",
            "Z_A":"Titles Z-A",
            "date_desc":"Release date: new to old",
            "date_asc":"Release date: old to new"
        }, 
        "sr-only": {
            "back_arrow":"back arrow button: previous page",
            "forward_arrow":"forward arrow button: next page",
            "up_arrow":"up arrow button: close menu",
            "down_arrow":"down arrow button: open menu",
            "close_menu":"close menu",
            "deactivated":"deactivated",
            "eye_icon":"eye icon: viewing options",
            "viewing_options":"viewing options",
            "more_options":"More Options",
            "info_icon": "info_icon, get more information",
            "more_info":"More Info",
            "film_icon":"film icon",
            "tv_icon":"TV icon",
            "search_bar":"magnifying glass icon (search)"
        },
        "movie_info":{"cast":"cast", "directing":"directing", "screenplay":"screenplay", "release_date":"release date"},
        "status_messages":{"loading":"loading","failed_to_load":"Failed to load","form_modal":"Make a selection","no_results":"No results"},
        "provider_options": {"buy":"Buy","rent":"Rent","stream":"Stream","with_ads":"With Ads"}
    },
    "es": {
        "english_name":"Spanish",
        "section_labels": {"languages":"idiomas","regions":"regiones","sort_options":"opciones de clasificación",
            "genre":"Género","decade":"Década","provider":"Proveedor"},
        "trending":"tendencias",
        "find":"buscar",
        "search":"buscar",
        "movie":"película",
        "movies":"películas",
        "tv":"tele",
        "tv_series":"Series de TV",
        "movie_title":"Título de la película",
        "all":"Todo",
        "sort_by": {
            "rating_desc":"Clasificación: Descendente",
            "rating_asc":"Clasificación: Ascendente",
            "A_Z":"Títulos: A-Z",
            "Z_A":"Títulos: Z-A",
            "date_desc":"Fecha de publicación: Descendente",
            "date_asc":"Fecha de publicación: Ascendente"
        }, 
        "sr-only": {
            "back_arrow":"botón de flecha hacia atrás: página anterior",
            "forward_arrow":"botón de flecha hacia atrás: página anterior",
            "up_arrow":"botón de flecha hacia abajo: abrir menú",
            "down_arrow":"botón de flecha hacia abajo: abrir menú",
            "close_menu":"abrir menú",
            "deactivated":"desactivado",
            "eye_icon":"opciones de visualización del símbolo del ojo",
            "viewing_options":"Opciones para ver películas.",
            "more_options":"Mas opciones",
            "info_icon": "símbolo de información",
            "more_info":"Más información",
            "film_icon":"símbolo de la película",
            "tv_icon":"símbolo de televisión",
            "search_bar":"símbolo de lupa (buscar)"
        },
        "movie_info":{"cast":"actores", "directing":"director", "screenplay":"guionista", "release_date":"Fecha de publicación"},
        "status_messages":{"loading":"obteniendo resultados","failed_to_load":"Descarga fracasó","form_modal":"Seleccione una opción","no_results":"no hay resultados"},
        "provider_options": {"buy":"Comprar","rent":"Alquilar","stream":"Retransmisión","with_ads":"Con anuncios"}
    },
    "fi": {
        "english_name":"Finnish",
        "section_labels": {"languages":"Kieli","regions":"alueilla","sort_options":"lajitteluvaihtoehdot",
            "genre":"Genre","decade":"Vuosikymmen","provider":"Palveluntarjoaja"},
        "trending":"Nousussa",
        "find":"etsiä",
        "search":"etsi",
        "movie":"elokuva",
        "movies":"elokuvia",
        "tv":"televisio",
        "tv_series":"televisio-ohjelmat",
        "movie_title":"Elokuvan nimi",
        "all":"Kaikki",
        "sort_by": {
            "rating_desc":"Arvosana laskeva",
            "rating_asc":"Arvosana nouseva",
            "A_Z":"Nimikkeet A-Z",
            "Z_A":"Nimikkeet Z-A",
            "date_desc":"Julkaisupäivä uudesta vanhaan",
            "date_asc":"Julkaisupäivä vanhasta uuteen"
        }, 
        "sr-only": {
            "back_arrow":"eteenpäin-nuolipainike: seuraava sivu",
            "forward_arrow":"Takaisinnuolipainike: edellinen sivu",
            "up_arrow":"ylänuolipainike: sulje valikko",
            "down_arrow":"alanuolipainike: avaa valikko",
            "close_menu":"sulje valikko",
            "deactivated":"deaktivoitu",
            "eye_icon":"silmäsymbolin katseluvaihtoehdot",
            "viewing_options":"Vaihtoehdot katseluun",
            "more_options":"Lisää vaihtoehtoja",
            "info_icon": "símbolo de información",
            "more_info":"Lisää tietoa",
            "film_icon":"elokuvan symboli",
            "tv_icon":"elokuvan symboli",
            "search_bar":"suurennuslasin symboli (etsiä)"
        },
        "movie_info":{"cast":"actores de cine", "directing":"dirección de cine", "screenplay":"Escritura de guiones", "release_date":"Julkaisupäivä"},
        "status_messages":{"loading":"tulosten saaminen","failed_to_load":"lataaminen epäonnistui","form_modal":"Valitse vaihtoehto","no_results":"Ei tuloksia"},
        "provider_options": {"buy":"Ostaa","rent":"Vuokrata","stream":"Striimattavana","with_ads":"Mainoksilla"}
    },
    "fr": {
        "english_name":"French",
        "section_labels": {"languages":"langues","regions":"Régions","sort_options":"options de tri",
            "genre":"Genre","decade":"Décennie","provider":"Fournisseur"},
        "trending":"tendance",
        "search":"rechercher des",
        "find":"trouver des",
        "movie":"film",
        "movies":"​les films",
        "tv":"la télé",
        "tv_series":"Séries TV",
        "movie_title":"Titre du film",
        "all":"Tout",
        "sort_by": {
            "rating_desc":"Note décroissante",
            "rating_asc":"Note croissante",
            "A_Z":"Titres A à Z",
            "Z_A":"Titres Z à A",
            "date_desc":"Date de sortie décroissante",
            "date_asc":"Date de sortie croissante"
        }, 
        "sr-only": {
            "back_arrow":"bouton fléché vers l'arrière : page précédente",
            "forward_arrow":"bouton fléché vers l'avant : page suivante",
            "up_arrow":"bouton fléché vers le haut : fermer le menu",
            "down_arrow":"bouton fléché vers le bas : ouvrir le menu",
            "close_menu":"fermer le menu",
            "deactivated":"désactivé",
            "eye_icon":"symbole de l'oeil",
            "viewing_options":"options de visionnage",
            "more_options":"Plus d'options",
            "info_icon": "symbole d'information",
            "more_info":"Plus d'information",
            "film_icon":"symbole du film",
            "tv_icon":"Symbole de la télévision",
            "search_bar":"symbole de la loupe (chercher)"
        },
        "movie_info":{"cast":"Acteurs de cinéma", "directing":"réalisation de films", "screenplay":"Scénario", "release_date":"Date de sortie"},
        "status_messages":{"loading":"obtenir des résultats","failed_to_load":"Échec du chargement","form_modal":"Choisissez","no_results":"Aucun résultat"},
        "provider_options": {"buy":"Acheter","rent":"Louer","stream":"Stream","with_ads":"avec des publicités"}
    },
    "he": {
        "english_name":"Hebrew",
        "section_labels": {"languages":"שפות","regions":"אזורים","sort_options":"אפשרויות מיון",
            "genre":"ז'ָאנר","decade":"עָשׂוֹר","provider":"מֵפִיץ"},
        "trending":"מגמתי",
        "find":"למצוא",
        "search":"חפש",
        "movie":"סרט",
        "movies":"סרטים",
        "tv":"טלוויזיה",
        "tv_series":"סדרה",
        "movie_title":"שם הסרט",
        "all":"הכל",
        "sort_by": {
            "rating_desc":"דירוג סדר יורד",
            "rating_asc":"דירוג בסדר עולה",
            "date_desc":"תאריך היציאה בסדר יורד",
            "date_asc":"תאריך שחרור בסדר עולה"
        }, 
        "sr-only": {
            "back_arrow":"לחצן חץ אחורה: העמוד הקודם",
            "forward_arrow":"לחצן חץ קדימה: העמוד הבא",
            "up_arrow":"לחצן חץ למעלה: סגור תפריט",
            "down_arrow":"לחצן חץ למטה: פתח תפריט",
            "close_menu":"סגור תפריט",
            "deactivated":"מושבת",
            "eye_icon":"סמל עין: אפשרויות צפייה",
            "viewing_options":"אפשרויות צפייה",
            "more_options":"אפשרויות נוספות",
            "info_icon": "סמל מידע",
            "more_info":"עוד מידע",
            "film_icon":"סמל הסרט",
            "tv_icon":"סמל טלוויזיה",
            "search_bar":"סמל זכוכית מגדלת"
        },
        "movie_info":{"cast":"שחקני", "directing":"בימוי", "screenplay":"כתיבת תסריט", "release_date":"תאריך פרסום"},
        "status_messages":{"loading":"מקבל תוצאות","failed_to_load":"ההורדה נכשלה","form_modal":"בחר אפשרות","no_results":"אין תוצאות"},
        "provider_options": {"buy":"לִקְנוֹת","rent":"השכרה","stream":"הזרמה","with_ads":"with advertisements"}
    },
    "hu": {
        "english_name":"Hungarian",
        "section_labels": {"languages":"nyelvek","regions":"régiók","sort_options":"rendezési lehetőségek",
            "genre":"Műfaj","decade":"Évtized","provider":"Szolgáltató"},
        "trending":"Népszerű",
        "find":"Keressen",
        "search":"keresése",
        "movie":"film",
        "movies":"filmeket",
        "tv":"Tévé",
        "tv_series":"tévéműsor",
        "movie_title":"filmcímek",
        "all":"Minden",
        "sort_by": {
            "rating_desc":"Értékelés: csökkenő",
            "rating_asc":"Értékelés: növekvő",
            "A_Z":"Címek: A-Z",
            "Z_A":"Címek: Z-A",
            "date_desc":"Megjelenés dátuma: csökkenő",
            "date_asc":"Megjelenés dátuma: növekvő"
        }, 
        "sr-only": {
            "back_arrow":"vissza nyíl gomb: előző oldal",
            "forward_arrow":"előre nyíl gomb: következő oldal",
            "up_arrow":"felfelé mutató nyíl gomb: menü bezárása",
            "down_arrow":"lefelé mutató nyíl gomb: menü megnyitása",
            "close_menu":"menü bezárása",
            "deactivated":"deaktiválva",
            "eye_icon":"szem szimbólum: megtekintési lehetőség",
            "viewing_options":"filmnézési lehetőségek",
            "more_options":"Több lehetőség",
            "info_icon": "információs szimbólum",
            "more_info":"Több információ",
            "film_icon":"film szimbólum",
            "tv_icon":"TV szimbólum",
            "search_bar":"nagyító szimbólum (keres)"
        },
        "movie_info":{"cast":"színészek", "directing":"filmrendezés", "screenplay":"forgatókönyvírás", "release_date":"Megjelenés dátuma"},
        "status_messages":{"loading":"az eredmények megszerzése","failed_to_load":"Sikertelen letöltés","form_modal":"Válassz egy lehetőséget","no_results":"Nincs eredmény"},
        "provider_options": {"buy":"megvesz","rent":"Bérlés","stream":"Stream","with_ads":"hirdetésekkel"}
    },
    "id": {
        "english_name":"Indonesian",
        "section_labels": {"languages":"bahasa","regions":"wilayah","sort_options":"opsi penyortiran",
            "genre":"Genre","decade":"Dasawarsa","provider":"Pemasok"},
        "trending":"Trending",
        "find":"Temukan",
        "search":"Cari",
        "person":"kru / aktor",
        "movie":"film",
        "movies":"film",
        "tv":"televisi",
        "tv_series":"serial televisi",
        "movie_title":"Judul film",
        "all":"Semuanya",
        "sort_by": {
            "rating_desc":"Peringkat menurun",
            "rating_asc":"Peringkat naik",
            "A_Z":"Judul A-Z",
            "Z_A":"Judul Z-A",
            "date_desc":"Tanggal rilis menurun",
            "date_asc":"Tanggal rilis meningkat"
        }, 
        "sr-only": {
            "back_arrow":"panah kembali: halaman sebelumnya",
            "forward_arrow":"tombol panah maju: halaman berikutnya",
            "up_arrow":"tombol panah atas: tutup menunya",
            "down_arrow":"tombol panah bawah: buka menunya",
            "close_menu":"tutup menunya",
            "deactivated":"dinonaktifkan",
            "eye_icon":"simbol mata",
            "viewing_options":"pilihan menonton",
            "more_options":"Lebih banyak pilihan",
            "info_icon": "simbol informasi",
            "more_info":"Informasi lebih lanjut",
            "film_icon":"simbol film",
            "tv_icon":"simbol televisi",
            "search_bar":"simbol kaca pembesar (Cari)"
        },
        "movie_info":{"cast":"akting", "directing":"penyutradaraan", "screenplay":"penulisan skenario", "release_date":"Tanggal rilis"},
        "status_messages":{"loading":"hasilnya sedang dimuat","failed_to_load":"gagal mengunduh","form_modal":"Pilih sebuah opsi","no_results":"Tidak ada hasil"},
        "provider_options": {"buy":"Membeli","rent":"Menyewa","stream":"Stream","with_ads":"dengan iklan"}
    },
    "it": {
        "english_name":"Italian",
        "section_labels": {"languages":"le lingue","regions":"regioni","sort_options":"opzioni di ordinamento",
            "genre":"Genere","decade":"Decennio","provider":"Fornitore"},
        "trending":"Di tendenza",
        "find":"Trova",
        "search":"Cerca",
        "person":"persona",
        "movie":"film",
        "movies":"films",
        "tv":"Serie TV",
        "tv_series":"Serie TV",
        "movie_title":"Titolo del film",
        "all":"Qualunque cosa",
        "sort_by": {
            "rating_desc":"Voto: discendente",
            "rating_asc":"Voto: ascendente",
            "A_Z":"Titoli: dalla A alla Z",
            "Z_A":"Titoli: dalla Z alla A",
            "date_desc":"Data di uscita: ascendente",
            "date_asc":"Data di uscita: discendente"
        }, 
        "sr-only": {
            "back_arrow":"Pulsante freccia indietro: pagina precedente",
            "forward_arrow":"Pulsante freccia avanti: pagina successiva",
            "up_arrow":"pulsante freccia su: chiusura del menu",
            "down_arrow":"Tasto freccia giù: apre il menu",
            "close_menu":"chiusura del menu",
            "deactivated":"pulsante disattivato",
            "eye_icon":"simbolo dell'occhio: opzioni di visualizzazione",
            "viewing_options":"opzioni di visualizzazione",
            "more_options":"Più opzioni",
            "info_icon": "simbolo di informazione",
            "more_info":"Maggiori informazioni",
            "film_icon":"simbolo del film",
            "tv_icon":"simbolo televisivo",
            "search_bar":"simbolo della lente di ingrandimento (cercare)"
        },
        "movie_info":{"cast":"recitazione", "directing":"regia", "screenplay":"sceneggiatura", "release_date":"Data di uscita"},
        "status_messages":{"loading":"ottenere risultati","failed_to_load":"impossibile caricare","form_modal":"selezionare e opzione","no_results":"o risultati"},
        "provider_options": {"buy":"Acquistare","rent":"Affitto","stream":"Stream","with_ads":"con annunci"}
    },
    "ja": {
        "english_name":"Japanese",
        "section_labels": {"languages":"言語","regions":"地域","sort_options":"並べ替えオプション",
            "genre":"ジャンル","decade":"十年","provider":"プロバイダー"},
        "trending":"動向",
        "find":"検索する",
        "search":"検索する",
        "movie":"映画",
        "movies":"映画",
        "tv":"テレビ",
        "tv_series":"テレビ番組",
        "movie_title":"映画のタイトル",
        "all":"すべて",
        "sort_by": {
            "rating_desc":"評価の降順",
            "rating_asc":"評価の昇順",
            "date_desc":"発売日の降順",
            "date_asc":"発売日の昇順"
        }, 
        "sr-only": {
            "back_arrow":"戻る矢印ボタン 前のページ",
            "forward_arrow":"進む矢印ボタン 次のページ",
            "up_arrow":"上矢印ボタン メニューを閉じる",
            "down_arrow":"下矢印ボタン メニューを開きます",
            "close_menu":"メニューを閉じる",
            "deactivated":"無効化された",
            "eye_icon":"目のシンボル:鑑賞オプショ",
            "viewing_options":"鑑賞オプショ",
            "more_options":"より多くのオプション",
            "info_icon": "情報シンボル",
            "more_info":"詳しくは",
            "film_icon":"映画のシンボル",
            "tv_icon":"テレビのシンボル",
            "search_bar":"虫眼鏡のシンボル 探す"
        },
        "movie_info":{"cast":"俳優", "directing":"映画監督", "screenplay":"脚本", "release_date":"発行日"},
        "status_messages":{"loading":"加载数据中","failed_to_load":"ダウンロードに失敗しました","form_modal":"選択肢一つを選択してください","no_results":"検索結果はありません"},
        "provider_options": {"buy":"買う","rent":"家賃","stream":"ストリーミング","with_ads":"広告あり"}
    },
    "ka": {
        "english_name":"Georgian",
        "section_labels": {"languages":"ენები","regions":"რეგიონები","sort_options":"დახარისხების ვარიანტები",
            "genre":"ჟანრი","decade":"ათწლეული","provider":"პროვაიდერი"},
        "trending":"Trending",
        "find":"იპოვნეთ",
        "search":"მოძებნეთ",
        "person":"პიროვნება",
        "movie":"ფილმი",
        "movies":"ფილმები",
        "tv":"სატელევიზიო",
        "tv_series":"სერიალი",
        "movie_title":"ფილმის სათაური",
        "all":"ყველაფერი",
        "sort_by": {
            "rating_desc":"რეიტინგი კლებადი",
            "rating_asc":"რეიტინგი აღმავალი",
            "date_desc":"გამოქვეყნების თარიღი დაღმავალი",
            "date_asc":"გამოქვეყნების თარიღი აღმავალი"
        }, 
        "sr-only": {
            "back_arrow":"უკანა ისრის ღილაკი: წინა გვერდი",
            "forward_arrow":"წინ ისრის ღილაკი: შემდეგი გვერდი",
            "up_arrow":"ზემოთ ისრის ღილაკი: მენიუს დახურვა",
            "down_arrow":"ქვემოთ ისრის ღილაკი: მენიუს გახსნა",
            "close_menu":"მენიუს დახურვა",
            "deactivated":"გამორთულია",
            "eye_icon":"თვალის სიმბოლო: ნახვის ვარიანტები",
            "viewing_options":"ნახვის პარამეტრები",
            "more_options":"Მეტი არჩევანი",
            "info_icon": "ინფორმაციის სიმბოლო",
            "more_info":"Მეტი ინფორმაცია",
            "film_icon":"ფილმის სიმბოლო",
            "tv_icon":"ტელევიზორის ხატულა",
            "search_bar":"გამადიდებელი შუშის სიმბოლო (ძებნა)"
        },
        "movie_info":{"cast":"მსახიობები", "directing":"რეჟისურა", "screenplay":"სცენარისწერა", "release_date":"გამოქვეყნების თარიღი"},
        "status_messages":{"loading":"იტვირთება","failed_to_load":"ჩამოტვირთვა ვერ მოხერხდა","form_modal":"აირჩიეთ ვარიანტი","no_results":"არანაირი შედეგი"},
        "provider_options": {"buy":"იყიდე","rent":"Ქირავდება","stream":"Stream","with_ads":"რეკლამებით"}
    },
    "ko": {
        "english_name":"Korean",
        "section_labels": {"languages":"언어","regions":"지역","sort_options":"정렬 옵션",
            "genre":"장르","decade":"10년","provider":"공급자"},
        "trending":"트렌딩",
        "find":"찾기",
        "search":"검색",
        "person":"인물",
        "movie":"영화",
        "movies":"영화",
        "tv":"TV",
        "tv_series":"텔레비전 시리즈",
        "movie_title":"영화 제목",
        "all":"모든 것",
        "sort_by": {
            "rating_desc":"순위별로 내림차순",
            "rating_asc":"등급 오름차순",
            "date_desc":"출판일 내림차순",
            "date_asc":"출판일 오름차순"
        }, 
        "sr-only": {
            "back_arrow":"앞으로 화살표 버튼 다음 페이지",
            "forward_arrow":"앞으로 화살표 버튼 다음 페이지",
            "up_arrow":"메뉴를 닫으려면 누르세요.",
            "down_arrow":"메뉴를 열려면 누르세요.",
            "close_menu":"메뉴를 닫다",
            "deactivated":"비활성화됨",
            "eye_icon":"눈 기호",
            "viewing_options":"관람 옵션",
            "more_options":"더 많은 옵션",
            "info_icon": "정보 기호",
            "more_info":"추가 정보",
            "film_icon":"영화의 상징",
            "tv_icon":"TV 기호",
            "search_bar":"돋보기 기호 (검색)"
        },
        "movie_info":{"cast":"배우", "directing":"화 연출", "screenplay":"각본", "release_date":"발행일"},
        "status_messages":{"loading":"결과를 얻는 중","failed_to_load":"다운로드 실패","form_modal":"옵션을 선택하세요","no_results":"결과가 없다"},
        "provider_options": {"buy":"구입하다","rent":"임대","stream":"스트리밍","with_ads":"광고와 함께"}
    },
    "lt": {
        "english_name":"Lithuanian",
        "section_labels": {"languages":"kalbomis","regions":"regionuose","sort_options":"rūšiavimo parinktys",
            "genre":"Žanras","decade":"Dešimtmetis","provider":"Teikėjas"},
        "trending":"Tendencijos",
        "find":"rasti",
        "search":"ieškoti",
        "person":"asmens",
        "movie":"filmo",
        "movies":"Filmai",
        "tv":"TV",
        "tv_series":"tv laidos",
        "movie_title":"Filmo pavadinimas",
        "all":"Viskas",
        "sort_by": {
            "rating_desc":"Įvertinimas: didėjantis",
            "rating_asc":"Įvertinimas: mažėjantis",
            "A_Z":"Pavadinimas: A-Z",
            "Z_A":"Pavadinimas: Z-A",
            "date_desc":"Paskelbimo data: mažėjančia tvarka",
            "date_asc":"Paskelbimo data: didėjančia tvarka"
        }, 
        "sr-only": {
            "back_arrow":"Rodyklės atgal mygtukas: ankstesnis puslapis",
            "forward_arrow":"Rodyklės pirmyn mygtukas: kitas puslapis",
            "up_arrow":"Rodyklės aukštyn mygtukas: uždaryti meniu",
            "down_arrow":"rodyklės žemyn mygtukas: atidarykite meniu",
            "close_menu":"uždaryti meniu",
            "deactivated":"išjungtas",
            "eye_icon":"akių simbolis: peržiūros parinktys",
            "viewing_options":"peržiūros parinktys",
            "more_options":"daugiau pasirinkimų",
            "info_icon": "informacinis simbolis",
            "more_info":"daugiau informacijos",
            "film_icon":"filmo simbolis",
            "tv_icon":"TV simbolis",
            "search_bar":"didinamojo stiklo simbolis (ieškoti)"
        },
        "movie_info":{"cast":"aktoriai", "directing":"režisūra", "screenplay":"scenarijų rašymas", "release_date":"paskelbimo data"},
        "status_messages":{"loading":"Rezultatų gavimas","failed_to_load":"Nepavyko atsisiųsti","form_modal":"pasirinkite parinktį","no_results":"jokių rezultatų"},
        "provider_options": {"buy":"Pirkti","rent":"Nuoma","stream":"Internetu","with_ads":"su skelbimais"}
    },
    "nl": {
        "english_name":"Dutch",
        "section_labels": {"languages":"talen","regions":"Regio's","sort_options":"sorteer opties",
            "genre":"Genre","decade":"Decennium","provider":"Aanbieder"},
        "trending":"Trending",
        "find":"vinden",
        "search":"Zoek naar",
        "person":"persoon",
        "movie":"film",
        "movies":"films",
        "tv":"TV",
        "tv_series":"tv-serie",
        "movie_title":"Film titel",
        "all":"Alles",
        "sort_by": {
            "rating_desc":"Waardering aflopend",
            "rating_asc":"Waardering oplopend",
            "A_Z":"Titels A-Z",
            "Z_A":"Titels Z-A",
            "date_desc":"Publicatie datum aflopend",
            "date_asc":"Publicatie datum oplopend"
        }, 
        "sr-only": {
            "back_arrow":"pijl-terug-knop: vorige pagina",
            "forward_arrow":"pijltje vooruit: volgende pagina",
            "up_arrow":"pijl-omhoog: sluit het menu",
            "down_arrow":"pijl-omlaag: open het menu",
            "close_menu":"uždaryti meniu",
            "deactivated":"gedeactiveerd",
            "eye_icon":"oogpictogram",
            "viewing_options":"opties voor het bekijken",
            "more_options":"meer opties",
            "info_icon": "informatie symbool",
            "more_info":"meer informatie",
            "film_icon":"film pictogram",
            "tv_icon":"tv-pictogram",
            "search_bar":"vergrootglaspictogram(Zoeken)"
        },
        "movie_info":{"cast":"acteurs", "directing":"regie", "screenplay":"scenarioschrijven", "release_date":"publicatie datum"},
        "status_messages":{"loading":"het verkrijgen van de resultaten","failed_to_load":"Laden mislukt","form_modal":"Kies een optie","no_results":"Geen resultaten"},
        "provider_options": {"buy":"Kopen","rent":"Huur","stream":"Stream","with_ads":"Met advertenties"}
    },
    "pl": {
        "english_name":"Polish",
        "section_labels": {"languages":"Języki","regions":"regiony","sort_options":"opcje sortowania", 
            "genre":"Gatunek","decade":"Dekada","provider":"Dostawca"},
        "trending":"Trending",
        "search":"Szukaj",
        "find":"znajdź",
        "person":"osoby",
        "movie":"filmu",
        "movies":"filmy",
        "tv":"TV",
        "tv_series":"serialu",
        "movie_title":"Tytuł filmu",
        "all":"Wszystko",
        "sort_by": {
            "rating_desc":"Ocena malejąca",
            "rating_asc":"Ocena rosnąca",
            "A_Z":"Tytuły A-Z",
            "Z_A":"Tytuły Z-A",
            "date_desc":"Data publikacji malejąco",
            "date_asc":"Data publikacji rosnąca"
        }, 
        "sr-only": {
            "back_arrow":"przycisk strzałki wstecz powrót do poprzedniej strony",
            "forward_arrow":"przycisk strzałki do przodu, przejdź do następnej strony",
            "up_arrow":"przycisk strzałki w górę zamyka menu",
            "down_arrow":"przycisk strzałki w dół otwiera menu",
            "close_menu":"przycisk zamyka menu",
            "deactivated":"dezaktywowany",
            "eye_icon":"symbol oka",
            "viewing_options":"opcje oglądania",
            "more_options":"więcej możliwości",
            "info_icon": "symbol informacyjny",
            "more_info":"więcej informacji",
            "film_icon":"symbol filmu",
            "tv_icon":"symbol telewizora",
            "search_bar":"symbol szkła powiększającego (Szukaj)"
        },
        "movie_info":{"cast":"aktorzy", "directing":"reżyseria", "screenplay":"scenariusz", "release_date":"Data publikacji"},
        "status_messages":{"loading":"uzyskanie wyników","failed_to_load":"Nie udało się pobrać","form_modal":"Wybierz opcję","no_results":"Brak wyników"},
        "provider_options": {"buy":"Kupić","rent":"Wynajem","stream":"Stream","with_ads":"Z reklamami"}
    },
    "pt": {
        "english_name":"Portuguese",
        "section_labels": {"languages":"línguas","regions":"regiões","sort_options":"opções de classificação",
            "genre":"Gênero","decade":"Década","provider":"Fornecedor"},
        "trending":"Tendências",
        "find":"Encontre",
        "search":"Pesquise",
        "person":"pessoa",
        "movie":"filme",
        "movies":"Filmes",
        "tv":"Na TV",
        "tv_series":"série de TV",
        "movie_title":"Título do filme",
        "all":"Tudo",
        "sort_by": {
            "rating_desc":"Classificação ascendente",
            "rating_asc":"Classificação decrescente",
            "A_Z":"Títulos de A a Z",
            "Z_A":"Títulos de Z a A",
            "date_desc":"Data de publicação decrescente",
            "date_asc":"Data de publicação ascendente"
        }, 
        "sr-only": {
            "back_arrow":"Botão de seta para voltar para voltar à página anterior",
            "forward_arrow":"Botão de seta para frente, vá para a próxima página",
            "up_arrow":"Botão de seta para cima fechar menu",
            "down_arrow":"Botão de seta para baixo para abrir o menu",
            "close_menu":"fechar menu",
            "deactivated":"desativado",
            "eye_icon":"símbolo do olho",
            "viewing_options":"opções de visualização",
            "more_options":"mais opções",
            "info_icon": "símbolo de informação",
            "more_info":"Mais Informações",
            "film_icon":"símbolo do filme",
            "tv_icon":"símbolo de televisão",
            "search_bar":"magnifying glass symbol (Pesquisar)"
        },
        "movie_info":{"cast":"atores", "directing":"direção", "screenplay":"escritor", "release_date":"Data de publicação"},
        "status_messages":{"loading":"obtendo resultados","failed_to_load":"Falha ao carregar","form_modal":"Selecione uma opção","no_results":"Nenhum resultado"},
        "provider_options": {"buy":"Comprar","rent":"Aluguel","stream":"Stream","with_ads":"com anúncios"}
    },
    "ro": {
        "english_name":"Romanian",
        "section_labels": {"languages":"limbi","regions":"regiuni","sort_options":"opțiuni de sortare",
            "genre":"Gen","decade":"Deceniu","provider":"Furnizor"},
        "trending":"Tendință",
        "find":"găsiți",
        "search":"caută",
        "person":"persoană",
        "movie":"film",
        "movies":"Filme",
        "tv":"Seriale",
        "tv_series":"Seriale TV",
        "movie_title":"Titlul filmului",
        "all":"Tot",
        "sort_by": {
            "rating_desc":"Evaluare: Crescator",
            "rating_asc":"Evaluare: Descrescătoare",
            "A_Z":"Titluri: A-Z",
            "Z_A":"Titluri: Z-A",
            "date_desc":"Data publicării: Ascendent",
            "date_asc":"Data publicării: Descendent"
        }, 
        "sr-only": {
            "back_arrow":"Buton săgeată înapoi: mergeți la pagina anterioară",
            "forward_arrow":"Butonul săgeată înainte: mergeți la pagina următoare",
            "up_arrow":"Buton săgeată sus: închide meniul",
            "down_arrow":"Buton săgeată în jos: deschide meniul",
            "close_menu":"închide meniul",
            "deactivated":"dezactivat",
            "eye_icon":"simbolul ochiului: opțiuni de vizualizare",
            "viewing_options":"opțiuni de vizionare",
            "more_options":"Mai multe opțiuni",
            "info_icon": "simbol de informare",
            "more_info":"Mai multe informatii",
            "film_icon":"simbolul filmului",
            "tv_icon":"Simbol TV",
            "search_bar":"simbolul lupei (caută)",
        },
        "movie_info":{"cast":"actori", "directing":"regie de film", "screenplay":"scenariu", "release_date":"Data Publicării"},
        "status_messages":{"loading":"obtinerea de rezultate","failed_to_load":"Descărcarea eșuată","form_modal":"selecteaza o optiune","no_results":"fara rezultate"},
        "provider_options": {"buy":"Cumpără","rent":"Chirie","stream":"Stream","with_ads":"cu reclame"}
    },
    "ru": {
        "english_name":"Russian",
        "section_labels": {"languages":"языки","regions":"регионы","sort_options":"параметры сортировки",
            "genre":"Жанр","decade":"Десятилетие","provider":"Поставщик"},
        "trending":"В тренде",
        "find":"Найти",
        "search":"Поиск",
        "person":"персону",
        "movie":"фильм",
        "movies":"Фильмы",
        "tv":"Сериалы",
        "tv_series":"сериал",
        "movie_title":"Название фильма",
        "all":"Все",
        "sort_by": {
            "rating_desc":"Рейтинг по убыванию",
            "rating_asc":"Рейтинг по возрастанию",
            "date_desc":"Дата публикации по убыванию",
            "date_asc":"Дата публикации по возрастанию"
        }, 
        "sr-only": {
            "back_arrow":"Кнопка со стрелкой назад, возврат на предыдущую страницу",
            "forward_arrow":"Кнопка со стрелкой вперед для перехода на следующую страницу",
            "up_arrow":"Кнопка со стрелкой вверх закрыть меню",
            "down_arrow":"Кнопка со стрелкой вниз открывает меню",
            "close_menu":"кнопка закрывает меню",
            "deactivated":"деактивирован",
            "eye_icon":"символ глаза",
            "viewing_options":"варианты просмотра",
            "more_options":"Больше вариантов",
            "info_icon": "информационный символ",
            "more_info":"Больше информации",
            "film_icon":"символ фильма",
            "tv_icon":"символ телевизора",
            "search_bar":"символ увеличительного стекла (искать)"
        },
        "movie_info":{"cast":"Актеры", "directing":"Режиссер", "screenplay":"Сценарий", "release_date":"Дата публикации"},
        "status_messages":{"loading":"получение результатов","failed_to_load":"Не удалось скачать","form_modal":"Выберите вариант","no_results":"Без результатов"},
        "provider_options": {"buy":"Купить","rent":"Арендовать","stream":"Онлайн","with_ads":"С рекламой"}
    },
    "sk": {
        "english_name":"Slovak",
        "section_labels": {"languages":"jazykoch","regions":"regiónoch","sort_options":"možnosti triedenia",
            "genre":"Žánru","decade":"desaťročie","provider":"Poskytovateľ"},
        "trending":"Trendujúce",
        "find":"Nájdite",
        "search":"Vyhľadajte",
        "person":"osobu",
        "movie":"film",
        "movies":"Filmy",
        "tv":"TV",
        "tv_series":"seriál",
        "movie_title":"Názov filmu",
        "all":"Všetko",
        "sort_by": {
            "rating_desc":"Hodnotenie zostupne",
            "rating_asc":"Hodnotenie Vzostupne",
            "A_Z":"Tituly A-Z",
            "Z_A":"Tituly Z-A",
            "date_desc":"Dátum publikácie Zostupne",
            "date_asc":"Dátum publikácie Vzostupne"
        }, 
        "sr-only": {
            "back_arrow":"Tlačidlo šípky späť na návrat na predchádzajúcu stránku",
            "forward_arrow":"Tlačidlo so šípkou dopredu pre prechod na ďalšiu stránku",
            "up_arrow":"Tlačidlo šípky nahor zatvorte ponuku",
            "down_arrow":"Tlačidlo so šípkou nadol otvorí ponuku",
            "close_menu":"tlačidlo na zatvorenie ponuky",
            "deactivated":"deaktivovaný",
            "eye_icon":"symbol oka",
            "viewing_options":"možnosti sledovania filmov",
            "more_options":"Viac možností",
            "info_icon": "informačný symbol",
            "more_info":"Viac informácií",
            "film_icon":"symbol filmu",
            "tv_icon":"symbol TV",
            "search_bar":"symbol lupy (Hľadať)"
        },
        "movie_info":{"cast":"herci", "directing":"režisér", "screenplay":"Scenár", "release_date":"Dátum publikácie"},
        "status_messages":{"loading":"získavanie výsledkov","failed_to_load":"Sťahovanie zlyhalo","form_modal":"Vyberte možnosť","no_results":"Žiadne výsledky"},
        "provider_options": {"buy":"Kúpiť","rent":"Nájomné","stream":"Streamované","with_ads":"s reklamami"}
    },
    "sl": {
        "english_name":"Slovenian",
        "section_labels": {"languages":"jezikov","regions":"regije","sort_options":"možnosti razvrščanja",
            "genre":"Žanr","decade":"desetletje","provider":"Ponudnik"},
        "trending":"Trending",
        "find":"Poiščite",
        "search":"Poiščite",
        "person":"oseba",
        "movie":"film",
        "movies":"filmi",
        "tv":"TV",
        "tv_series":"tv oddaje",
        "movie_title":"Naslov filma",
        "all":"Vse",
        "sort_by": {
            "rating_desc":"Ocena pada",
            "rating_asc":"Ocena narašča",
            "A_Z":"Addresses from A to Z",
            "Z_A":"Addresses from Z to A",
            "date_desc":"Datum objave padajoče",
            "date_asc":"Datum objave naraščajoče"
        }, 
        "sr-only": {
            "back_arrow":"Puščični gumb za vrnitev na prejšnjo stran",
            "forward_arrow":"Gumb s puščico naprej pojdi na naslednjo stran",
            "up_arrow":"Gumb s puščico navzgor zapre meni",
            "down_arrow":"Puščični gumb navzdol odpre meni",
            "close_menu":"tlačidlo zatvorí menu",
            "deactivated":"deaktivirano",
            "eye_icon":"simbol oči",
            "viewing_options":"možnosti gledanja",
            "more_options":"Več možnosti",
            "info_icon": "informacijski simbol",
            "more_info":"Več informacij",
            "film_icon":"filmski simbol",
            "tv_icon":"tv simbol",
            "search_bar":"simbol povečevalnega stekla (raziskovati)"
        },
        "movie_info":{"cast":"igralci", "directing":"direktor", "screenplay":"scenarij", "release_date":"Datum objave"},
        "status_messages":{"loading":"pridobivanje rezultatov","failed_to_load":"Prenos ni uspel","form_modal":"Izberite možnost","no_results":"Brez rezultatov"},
        "provider_options": {"buy":"Nakup","rent":"Najemnina","stream":"Stream","with_ads":"z oglasi"}
    },
    "sr": {
        "english_name":"Serbian",
        "section_labels": {"languages":"језика","regions":"региони","sort_options":"опције сортирања",
            "genre":"Жанр","decade":"Декада","provider":"Провајдер"},
        "trending":"У тренду",
        "find":"Пронађите",
        "search":"Претражујте",
        "person":"особа",
        "movie":"филм",
        "movies":"Филмови",
        "tv":"ТВ",
        "tv_series":"TB серију",
        "movie_title":"Наслов филма",
        "all":"Све",
        "sort_by": {
            "rating_desc":"Оцена: опадајућа",
            "rating_asc":"Оцена: растућа",
            "date_desc":"Датум објављивања у опадајућем ",
            "date_asc":"Датум објављивања у растућем"
        }, 
        "sr-only": {
            "back_arrow":"Дугме са стрелицом уназад: идите на претходну страницу",
            "forward_arrow":"Дугме са стрелицом унапред: идите на следећу страницу",
            "up_arrow":"Дугме са стрелицом унапред: затворите мени",
            "down_arrow":"Дугме са стрелицом унапред: отворите мени",
            "close_menu":"затворите мени",
            "deactivated":"деактивирано",
            "eye_icon":"симбол ока: опције прегледа",
            "viewing_options":"опције за гледање",
            "more_options":"Више опција",
            "info_icon": "симбол информација",
            "more_info":"Више информација",
            "film_icon":"филмски симбол",
            "tv_icon":"ТВ симбол",
            "search_bar":"симбол лупе (Претражите)"
        },
        "movie_info":{"cast":"глумци", "directing":"редитељ", "screenplay":"сценарио", "release_date":"Датум издавања"},
        "status_messages":{"loading":"добијање резултата","failed_to_load":"Преузимање није успело","form_modal":"изаберите опцију","no_results":"Нема резултата"},
        "provider_options": {"buy":"Купи","rent":"Закуп","stream":"Стрим","with_ads":"са рекламама"}
    },
    "sv": {
        "english_name":"Swedish",
        "section_labels": {"languages":"språk","regions":"regioner","sort_options":"sorteringsalternativ",
            "genre":"Genre","decade":"Årtionde","provider":"Leverantör"},
        "trending":"Trendande",
        "find":"Hitta",
        "search":"Sök efter",
        "movie":"film",
        "movies":"Filmer",
        "tv":"TV",
        "tv_series":"tv-serie",
        "movie_title":"filmtitel",
        "all":"Allt",
        "sort_by": {
            "rating_desc":"Betyg: fallande",
            "rating_asc":"Betyg: stigande",
            "date_desc":"Publiceringsdatum: fallande",
            "date_asc":"Publiceringsdatum: stigande"
        }, 
        "sr-only": {
            "back_arrow":"bakåtpil: gå till föregående sida",
            "forward_arrow":"framåtpilknapp: gå till nästa sida",
            "up_arrow":"uppåtpil: stäng menyn",
            "down_arrow":"nedåtpil: öppna menyn",
            "close_menu":"stäng menyn",
            "deactivated":"avaktiverad",
            "eye_icon":"ögonsymbol: visningsalternativ",
            "viewing_options":"alternativ för filmvisning",
            "more_options":"Fler alternativ",
            "info_icon": "informationssymbol",
            "more_info":"Mer information",
            "film_icon":"film symbol",
            "tv_icon":"TV-symbol",
            "search_bar":"symbol för förstoringsglas (Sök)"
        },
        "movie_info":{"cast":"skådespelare", "directing":"regissör", "screenplay":"manus", "release_date":"Publiceringsdatum"},
        "status_messages":{"loading":"laddar resultaten","failed_to_load":"Nedladdning misslyckad","form_modal":"Välj ett alternativ","no_results":"inga resultat"},
        "provider_options": {"buy":"köpa","rent":"Hyra","stream":"Strömmas","with_ads":"med annonser"}
    },
    "th": {
        "english_name":"Thai",
        "section_labels": {"languages":"ภาษา","regions":"ภูมิภาค","sort_options":"ตัวเลือกการเรียงลำดับ",
            "genre":"ประเภท","decade":"ทศวรรษ","provider":"ผู้ให้บริการ"},
        "trending":"Trending",
        "find":"ค้นหาภ",
        "serach":"ค้นหาภ",
        "person":"บุคคล",
        "movie":"ภาพยนตร์",
        "movies":"ภาพยนตร์",
        "tv":"โทรทัศน์",
        "tv_series":"รายการโทรทัศน์",
        "movie_title":"ชื่อหนัง",
        "all":"ทุกอย่าง",
        "sort_by": {
            "rating_desc":"เรตติ้งเรียงลำดับจากมากไปน้อย",
            "rating_asc":"การให้คะแนนจะเรียงลำดับจากน้อยไปหามาก",
            "date_desc":"วันที่ตีพิมพ์จากมากไปน้อย",
            "date_asc":"วันที่ตีพิมพ์จากน้อยไปหามาก"
        }, 
        "sr-only": {
            "back_arrow":"ปุ่มลูกศรย้อนกลับ กลับไปยังหน้าก่อนหน้า",
            "forward_arrow":"ปุ่มลูกศรไปข้างหน้าไปที่หน้าถัดไป",
            "up_arrow":"ปุ่มลูกศรขึ้นปิดเมนู",
            "down_arrow":"ปุ่มลูกศรลงเปิดเมนู",
            "close_menu":"ปุ่มปิดเมนู",
            "deactivated":"ปิดใช้งานแล้ว",
            "eye_icon":"ตัวเลือกการดูปุ่มสัญลักษณ์ตา",
            "viewing_options":"ตัวเลือกการชมภาพย",
            "more_options":"ตัวเลือกเพิ่มเติม",
            "info_icon": "สัญลักษณ์ข้อมูล",
            "more_info":"ข้อมูลเพิ่มเติม",
            "film_icon":"สัญลักษณ์ภาพยนตร์",
            "tv_icon":"สัญลักษณ์ทีวี",
            "search_bar":"สัญลักษณ์แว่นขยาย (สอบถาม)"
        },
        "movie_info":{"cast":"นักแสดงภาพย", "directing":"ผู้กำกับภาพยนต", "screenplay":"บทภาพยนตร์", "release_date":"วันที่ตีพิมพ์"},
        "status_messages":{"loading":"กำลังโหลดผลลัพธ์","failed_to_load":"ดาวน์โหลดไม่สำเร็จ","form_modal":"เลือกตัวเลือก","no_results":"ไม่มีผลลัพธ์"},
        "provider_options": {"buy":"ซื้อ","rent":"เช่า","stream":"Stream","with_ads":"ด้วยโฆษณา"}

    },
    "tr": {
        "english_name":"Turkish",
        "section_labels": {"languages":"Diller","regions":"bölgeler","sort_options":"sıralama seçenekleri",
            "genre":"Tür","decade":"On yıl","provider":"Sağlayıcı"},
        "trending":"Trend",
        "find":"bul",
        "search":"ara",
        "person":"kişi",
        "movie":"film",
        "movies":"Filmler",
        "tv":"TV",
        "tv_series":"dizi",
        "movie_title":"Film başlığı",
        "all":"Her şey",
        "sort_by": {
            "rating_desc":"Derecelendirme azalan",
            "rating_asc":"Derecelendirme Artan",
            "A_Z":"A'dan Z'ye Başlıklar",
            "Z_A":"Z'dan A'ye Başlıklar",
            "date_desc":"Azalan sırada yayın tarihi",
            "date_asc":"Artan sırada yayınlar"
        }, 
        "sr-only": {
            "back_arrow":"Geri ok düğmesi önceki sayfaya döner",
            "forward_arrow":"İleri ok düğmesi sonraki sayfaya gider",
            "up_arrow":"Menüyü kapatmak için yukarı ok düğmesi",
            "down_arrow":"Menüyü açmak için aşağı ok düğmesi",
            "close_menu":"Menüyü kapatmak için düğme",
            "deactivated":"devre dışı bırakıldı",
            "eye_icon":"göz sembolü",
            "viewing_options":"Film İzleme seçenekleri",
            "more_options":"Daha fazla seçenek",
            "info_icon": "bilgi sembolü",
            "more_info":"daha fazla bilgi",
            "film_icon":"film sembolü",
            "tv_icon":"televizyon sembolü",
            "search_bar":"büyüteç sembolü (ara)"
        },
        "movie_info":{"cast":"aktörleri", "directing":"yönetmeni", "screenplay":"senaryo", "release_date":"Yayın tarihi"},
        "status_messages":{"loading":"Sonuç Alma","failed_to_load":"İndirme başarısız","form_modal":"Bir seçenek seçin","no_results":"Sonuç yok"},
        "provider_options": {"buy":"Satın almak","rent":"Kira","stream":"Yayın Akışı","with_ads":"reklamlarla"}
    },
    "vi": {
        "english_name":"Vietnamese",     
        "section_labels": {"languages":"ngôn ngữ","regions":"vùng","sort_options":"tùy chọn sắp xếp",
            "genre":"thể loại","decade":"Thập kỷ","provider":"Các nhà cung cấp"},
        "trending":"Trending",
        "find":"tìm",
        "search":"tìm kiếm",
        "person":"người",
        "movie":"phim",
        "movies":"phim",
        "tv":"TV",
        "tv_series":"truyền hình nhiều tập",
        "movie_title":"Tiêu đề phim",
        "all":"Mọi thứ",
        "sort_by": {
            "rating_desc":"Thứ tự xếp hạng giảm dần",
            "rating_asc":"Thứ tự xếp hạng tăng dần",
            "date_desc":"Thứ tự giảm ngày bản",
            "date_asc":"Thứ tự tăng ngày phát hành"
        }, 
        "sr-only": {
            "back_arrow":"Nút mũi tên quay lại quay lại trang trước",
            "forward_arrow":"Nút mũi tên chuyển tiếp đi tới trang tiếp theo",
            "up_arrow":"Nút mũi tên lên để đóng menu",
            "down_arrow":"Nút mũi tên xuống để mở menu",
            "close_menu":"nút để đóng menu",
            "deactivated":"tàn tật",
            "eye_icon":"biểu tượng mắt",
            "viewing_options":"tùy chọn xem",
            "more_options":"lựa chọn khác",
            "info_icon": "biểu tượng thông tin",
            "more_info":"thêm thông tin",
            "film_icon":"biểu tượng phim",
            "tv_icon":"biểu tượng tivi",
            "search_bar":"biểu tượng kính lúp (tìm kiếm)"
        },
        "movie_info":{"cast":"người biểu diễn", "directing":"đạo diễn", "screenplay":"kịch bản", "release_date":"Ngày xuất bản"},
        "status_messages":{"loading":"đang tải kết quả","failed_to_load":"Không thể tải xuống","form_modal":"Chọn một tùy chọn","no_results":"Ko có kết quả"},
        "provider_options": {"buy":"Mua","rent":"Thuê","stream":"Stream","with_ads":"với quảng cáo"}
    },
    "zh": {
        "english_name":"Mandarin", 
        "section_labels": {"languages":"语言","regions":"地区","sort_options":"排序选项",
            "genre":"类型","decade":"十年","provider":"提供者"},
        "trending":"趋势",
        "find":"查找",
        "search":"查找",
        "person":"人",
        "movie":"电影",
        "movies":"电影",
        "tv":"电视",
        "tv_series":"剧集",
        "movie_title":"电影标题",
        "all":"一切",
        "sort_by": {
            "rating_desc":"评分降序",
            "rating_asc":"评级上升",
            "date_desc":"出版日期降序",
            "date_asc":"出版日期升序"
        }, 
        "sr-only": {
            "back_arrow":"后退箭头按钮转到上一页",
            "forward_arrow":"前进箭头按钮转到下一页",
            "up_arrow":"向上箭头按钮关闭菜单",
            "down_arrow":"向下箭头按钮打开菜单",
            "close_menu":"按钮关闭菜单",
            "deactivated":"停用",
            "eye_icon":"眼睛符号 查看选项",
            "viewing_options":"查看选项",
            "more_options":"更多的选择",
            "info_icon": "信息符号",
            "more_info":"更多信息",
            "film_icon":"电影符号",
            "tv_icon":"电视符号",
            "search_bar":"放大镜符号 搜寻"
        },
        "movie_info":{"cast":"影演员", "directing":"导演", "screenplay":"剧本", "release_date":"发布日期"},
        "status_messages":{"loading":"正在取得成果","failed_to_load":"下载失败","form_modal":"选择一个选项","no_results":"没有结果"},
        "provider_options": {"buy":"买","rent":"租","stream":"流媒体","with_ads":"有广告"}
    }
}

export { TransObj, LanguagesObj }
